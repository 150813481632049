import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import {
  addMotorbike,
  fetchMotorbikes,
  setAddModalOpen,
  setMotorbikeFilterValue,
} from "store/slice/MotorbikeSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Motorbike.module.css";
import { MotorbikeForm } from "./components/MotorbikeForm";
import { MotorbikeTable } from "./components/MotorbikeTable";

export const Motorbike = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectAddModalOpen = useSelectorRoot(
    (state) => state.motorbike.addModalOpen
  );
  const selectErrMsg = useSelectorRoot((state) => state.motorbike.errMsg);
  const selectMotorbikeFilterValue = useSelectorRoot(
    (state) => state.motorbike.filterValue
  );
  const [addMotorbikeForm] = useForm();
  const dispatch = useDispatchRoot();

  useEffect(() => {
    dispatch(fetchMotorbikes({ pageSize: 25 }));
  }, [dispatch]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);

  const handleAddMotorbike = async () => {
    const motorbikeValue = await addMotorbikeForm
      .validateFields()
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
    if (!motorbikeValue) return;
    dispatch(
      addMotorbike({
        ...motorbikeValue,
        registrationDate: dayjs(motorbikeValue.registrationDate),
      })
    );
  };
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setMotorbikeFilterValue({
          ...selectMotorbikeFilterValue,
          search: e.target.value,
        })
      );
    }, 1000);
    setTimer(timeoutId);
  };
  return (
    <div className={styles.motorbikeContainer}>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <Typography.Text strong style={{ fontSize: 20 }}>
            Danh sách xe
          </Typography.Text>
          <div>
            <Input.Search
              placeholder="Tìm kiếm xe"
              onChange={handleSearch}
              allowClear
            />
          </div>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            dispatch(setAddModalOpen(true));
            addMotorbikeForm.resetFields();
          }}
        >
          Thêm mới xe
        </Button>
      </div>
      <MotorbikeTable />
      {selectAddModalOpen ? (
        <Modal
          title={"Thêm mới xe"}
          open={selectAddModalOpen}
          onCancel={() => dispatch(setAddModalOpen(false))}
          onOk={handleAddMotorbike}
          okText="Thêm"
        >
          <MotorbikeForm form={addMotorbikeForm} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};
