import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import {
  ICreateMotorbike,
  IParams,
  IUpdateMotorbike,
  IUpdateOperatorMotorbike,
} from "common/define-types";
import Utils from "common/Utils";
import moment from "moment";

const api = `${SYSTEM_CONSTANTS.API_URL}/Motorbike`;

export const getMotorbikeById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getAllMotorbikes = (params?: IParams & Record<string, any>): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const createMotorbike = (body: ICreateMotorbike[]): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateMotorbike = (body: IUpdateMotorbike): Observable<any> => {
  return HttpClient.put(`${api}?id=${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const deleteMotorbikeById = (motorbikeId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${motorbikeId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getMotorbikeTicketsByMotorbikeId = (
  motorbikeId: string,
  params?: IParams
): Observable<any> => {
  if (params) {
    return HttpClient.get(
      `${api}/${motorbikeId}/motorbikeTicket?${Utils.getParamsString({
        endDate: moment().add(6, "months").endOf("day").toISOString() || "",
        startDate:
          moment().subtract(6, "months").startOf("day").toISOString() || "",
        ...params,
      })}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}/${motorbikeId}/motorbikeTicket`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const getMotorbikesByDay = (params: IParams): Observable<any> => {
  return HttpClient.get(
    `${api}/motorbikeByDay?${Utils.getParamsString(params)}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const createNoteMotorbikeByDay = (
  body: IUpdateOperatorMotorbike
): Observable<any> => {
  return HttpClient.post(
    `${api}/noteMotorbike?motorBikeId=${body.motorBikeId}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const updateNoteMotorbikeByDay = (
  id: string,
  body: IUpdateOperatorMotorbike
): Observable<any> => {
  return HttpClient.put(
    `${api}/noteMotorbikeByDay?id=${id}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const exportReturnJasmineMotorbikes = (params?: {
  selectDate: string;
}): Observable<any> => {
  return HttpClient.get(`${api}/ExportPDF?selectDate=${params?.selectDate}`, {
    headers: {
      "Content-Type": "application/pdf",
      "Content-Disposition": 'attachment; filename="filename.pdf"',
      "Cache-Control": "private",
    },
    responseType: "blob",
  }).pipe(
    map(
      (res) => {
        return (res as any) || null;
      },
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const checkInMotorbike = (motorbikeId: string): Observable<any> => {
  return HttpClient.put(`${api}/CheckIn?id=${motorbikeId}`, {}).pipe(
    map(
      (res) => {
        return (res as any) || null;
      },
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const unCheckInMotorbike = (motorbikeId: string): Observable<any> => {
  return HttpClient.put(`${api}/CheckOut?id=${motorbikeId}`, {}).pipe(
    map(
      (res) => {
        return (res as any) || null;
      },
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
