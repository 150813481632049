import {
  Checkbox,
  Divider,
  Form,
  FormInstance,
  Input,
  //   InputNumber,
  Radio,
  Select,
  Typography,
} from "antd";
import {
  ComboTourItemType,
  ExtraServiceCode,
  IComboTour,
  ITicket_ExtraService,
  IsDriverType,
  SelfMotor,
  VehicleType,
} from "common/define-types";
import Utils from "common/Utils";
import { MoneyInput } from "components/Input/MoneyInput";
import { BusSelect } from "components/Selects/RouterStationBusSelect";
import { LocationSelect } from "components/Selects/LocationSelect";
import { useEffect, useState } from "react";
import { useSelectorRoot } from "store/store";
import ExtraServiceTab from "pages/Booking/components/ExtraService/ExtraServiceTab";
import moment from "moment";
import styles from "../ComboTourCategory.module.css";
import { ServiceType } from "../../ExtraService/components/ExtraServiceForm";
interface ComboTourFormProps {
  forms: {
    comboTourForm: FormInstance<any>;
    extraServiceForm: FormInstance<any>;
  };
  editingComboTour?: IComboTour | null;
}
export const ComboTourForm = ({
  forms,
  editingComboTour,
}: ComboTourFormProps) => {
  const { comboTourForm, extraServiceForm } = forms;

  const [hasCombo, setHasCombo] = useState(false);
  const [selectedExtraServices, setSelectedExtraServices] = useState({});
  const selectExtraServices = useSelectorRoot(
    (state) => state.booking.extraServices
  );
  const selectTours = useSelectorRoot((state) => state.booking.tours);
  // const selectRoomTemplates = useSelectorRoot(
  //   (state) => state.roomTemplate.roomTemplates
  // );

  const fromLocationId = Form.useWatch("fromLocationId", comboTourForm);
  const endLocationId = Form.useWatch("endLocationId", comboTourForm);
  const vehicleType = Form.useWatch('VehicleType', comboTourForm);

  const isDriverOptions = [
    {
      label: "Có lái",
      value: IsDriverType.YES,
    },
    {
      label: "Tự lái",
      value: IsDriverType.NO,
      hidden: vehicleType === VehicleType.CAR
    },
  ]

  const selfMotorOptions = [
    {
      label: "Xe của Jasmine",
      value: SelfMotor.NO,
    },
    {
      label: "Xe của khách",
      value: SelfMotor.YES,
      hidden: vehicleType === VehicleType.CAR
    },
  ]
  // const editingBusCombos =
  //   editingComboTour && editingComboTour.ListItem
  //     ? editingComboTour.ListItem?.filter(
  //         (item) => item.ItemType === ComboTourItemType.Bus
  //       )
  //     : [];
  // parse data into form
  useEffect(() => {
    if (editingComboTour) {
      const foundTour = editingComboTour.ListItem?.find(
        (item) => item.ItemType === ComboTourItemType.Tour
      );
      const foundTypeMotor = editingComboTour.ListItem?.find(
        (item) => item.ItemType === ComboTourItemType.MotorPrice
      );
      const foundDriverPrice = editingComboTour.ListItem?.find(
        (item) => item.ItemType === ComboTourItemType.DriverPrice
      );

      const busCombos =
        editingComboTour.ListItem?.filter(
          (item) => item.ItemType === ComboTourItemType.Bus
        ) || [];
      const hangHoaCombos =
        editingComboTour.ListItem?.filter(
          (item) => item.ItemType === ComboTourItemType.HangHoa
        ) || [];
      const roomTemplateCombos =
        editingComboTour.ListItem?.filter(
          (item) => item.ItemType === ComboTourItemType.RoomTemplate
        ) || [];

      if (
        busCombos.length ||
        (hangHoaCombos && hangHoaCombos.length) ||
        (roomTemplateCombos && roomTemplateCombos.length)
      ) {
        setHasCombo(true);
      } else {
        setHasCombo(false);
      }

      if (busCombos.length) {
        const busC1 = busCombos.find(
          (item) =>
            item.DMUC_Router_Station &&
            item.DMUC_Router_Station.DMUC_Router?.LocaltionEndId ===
              Utils.HaGiangLocationId
        );
        const busC2 = busCombos.find(
          (item) =>
            item.DMUC_Router_Station &&
            item.DMUC_Router_Station.DMUC_Router?.LocaltionStartId ===
              Utils.HaGiangLocationId
        );
        if (busC1) {
          comboTourForm?.setFields(
            Utils.objectToAntFormData({
              fromLocationId:
                busC1.DMUC_Router_Station?.DMUC_Router.LocaltionStartId,
              fromBusId: busC1.Id,
            })
          );
        }
        if (busC2) {
          comboTourForm?.setFields(
            Utils.objectToAntFormData({
              endLocationId:
                busC2.DMUC_Router_Station?.DMUC_Router.LocaltionEndId,
              endBusId: busC2.Id,
            })
          );
        }
      } else {
        comboTourForm?.setFields(
          Utils.objectToAntFormData({
            fromLocationId: undefined,
            fromBusId: undefined,
            endLocationId: undefined,
            endBusId: undefined,
          })
        );
      }

      let datA_TOUR_CHHangHoaDichVuKhacs: ITicket_ExtraService[] = [];
      let selectedComboExtraServices = {};
      if (
        (hangHoaCombos && hangHoaCombos.length) ||
        (roomTemplateCombos && roomTemplateCombos.length)
      ) {
        if (hangHoaCombos && hangHoaCombos.length) {
          hangHoaCombos.forEach((combo) => {
            const DMUC_CHHangHoaDichVuKhacId =
              combo.DMUC_CHHangHoaDichVuKhacs?.Id;
            if (DMUC_CHHangHoaDichVuKhacId) {
              const foundExtraService = selectExtraServices.find(
                (extraService) => extraService.id === DMUC_CHHangHoaDichVuKhacId
              );
              selectedComboExtraServices = {
                ...selectedComboExtraServices,
                [DMUC_CHHangHoaDichVuKhacId]: true,
              };
              datA_TOUR_CHHangHoaDichVuKhacs.push({
                dmuC_HangHoaId: combo.ItemId,
                price: combo.Price,
                soLuong: 1, // TODO: parse soLuong
                dmuC_CHHangHoaDichVuKhacId: DMUC_CHHangHoaDichVuKhacId,
                extraService: foundExtraService,
              });
            }
          });
        }

        if (roomTemplateCombos && roomTemplateCombos.length) {
          // const DMUC_CHHangHoaDichVuKhacId =
          //   roomTemplateCombos[0]?.DMUC_CHHangHoaDichVuKhacId;
          // if (DMUC_CHHangHoaDichVuKhacId) {
          const foundExtraService = selectExtraServices.find(
            (extraService) => extraService.code === ExtraServiceCode.CHANGE_ROOM
          );
          if (foundExtraService) {
            selectedComboExtraServices = {
              ...selectedComboExtraServices,
              [foundExtraService.id]: true,
            };
            datA_TOUR_CHHangHoaDichVuKhacs.push({
              price: roomTemplateCombos[0].Price,
              soLuong: 1, // TODO: parse soLuong
              dmuC_CHHangHoaDichVuKhacId: foundExtraService.id,
              roomTemplateIds: roomTemplateCombos.map((room) => room.ItemId),
              extraService: foundExtraService,
            });
          }
          // extraServiceForm.setFieldValue(
          //   "roomTemplateIds",
          //   roomTemplateCombos.map((room) => room.ItemId)
          // );
          // }
        }
        extraServiceForm.setFieldValue(
          "datA_TOUR_CHHangHoaDichVuKhacs",
          datA_TOUR_CHHangHoaDichVuKhacs
        );
        setSelectedExtraServices(selectedComboExtraServices);
      } else {
        setSelectedExtraServices({});
        extraServiceForm.resetFields();
      }

      comboTourForm?.setFields(
        Utils.objectToAntFormData({
          ...editingComboTour,
          TourId: foundTour?.Id,
          IsDriver: foundDriverPrice?.IsDriver,
          SelfMotor: foundTypeMotor?.Value,
        })
      );
    }
  }, [editingComboTour, comboTourForm, extraServiceForm, selectExtraServices]);

  return (
    <>
      <Form
        form={comboTourForm}
        labelCol={{ span: 24 }}
        labelAlign="left"
        className="noMargin_form-item"
        initialValues={{
          IsDriver: IsDriverType.YES,
          SelfMotor: SelfMotor.NO,
          VehicleType: VehicleType.CAR,
        }}
      >
        <Form.Item
          label={"Mã Tour"}
          name={"Code"}
          rules={[
            {
              required: !editingComboTour,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input disabled={!!editingComboTour} />
        </Form.Item>
        <Form.Item
          name={"TourId"}
          label={"Loại Tour"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Select
            allowClear
            style={{ minWidth: 150, flex: 1 }}
            options={selectTours.map((tour) => ({
              label: tour.description,
              value: tour.id,
            }))}
            placeholder="-- Chọn loại tour --"
          />
        </Form.Item>
        <Form.Item
          name={"Title"}
          label={"Tên tour"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input placeholder="E.g: 3 Ngày 2 Đêm Bus 2 way" />
        </Form.Item>
        <Form.Item name={"Name"} label={"Mô tả"}>
          <Input placeholder="E.g: 3 Ngày 2 Đêm" />
        </Form.Item>
        <Form.Item
          label={"Kiểu xe"}
          name={"VehicleType"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Radio.Group
            options={[
              {
                label: "Ô tô",
                value: VehicleType.CAR,
              },
              {
                label: "Xe máy",
                value: VehicleType.MOTORBIKE,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={"Loại lái xe"}
          name={"IsDriver"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Radio.Group
            options={isDriverOptions.filter(option => !option.hidden)}
          />
        </Form.Item>
        <Form.Item
          label={"Loại xe"}
          name={"SelfMotor"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Radio.Group
            options={selfMotorOptions.filter(option => !option.hidden)}
          />
        </Form.Item>
        <Form.Item
          name={"GiaHienThi"}
          label={"Giá bán"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <MoneyInput min={0} />
        </Form.Item>
        {/* <Form.Item name={"Note"} label={"Ghi chú"}>
        <Input.TextArea />
      </Form.Item> */}
        <div>
          <Checkbox
            checked={hasCombo}
            onChange={(e) => setHasCombo(e.target.checked)}
          >
            <Typography.Text strong style={{ fontSize: 18 }}>
              Combo
            </Typography.Text>
          </Checkbox>
        </div>
        {hasCombo && (
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            <li>
              <Typography.Text className={styles.comboSection_title}>
                Bus 2 way
              </Typography.Text>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item style={{ display: "none" }} name={"fromBusPrice"}>
                  <Input style={{ display: "none" }} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  label={"Đến Jasmine từ"}
                  name={"fromLocationId"}
                >
                  <LocationSelect testId="fromBus-Location" />
                </Form.Item>
                <Form.Item
                  label={`Loại Bus đến`}
                  name={"fromBusId"}
                  rules={[
                    {
                      required: !!fromLocationId || !!endLocationId,
                      message: "Không được bỏ trống",
                    },
                  ]}
                >
                  <BusSelect
                    filterValue={{
                      locationFromId: fromLocationId,
                      locationToId: Utils.HaGiangLocationId,
                      date: null,
                      // dockerId: editingComboTour?.Id,
                      // ? editingBusCombos?.length > 0
                      //   ? editingComboTour.Id
                      //   : undefined
                      // : undefined,
                    }}
                    testId="fromBus-Bus"
                    onChange={(bus) => {
                      comboTourForm.setFieldValue("fromBusId", bus?.id);
                      comboTourForm.setFieldValue(
                        "fromBusPrice",
                        bus ? Utils.toNumber(bus.price, 0) : 0
                      );
                    }}
                    busPriceProp="price"
                  />
                </Form.Item>
                <div
                  className="gap"
                  style={{ height: 10, width: "100%" }}
                ></div>
                <Form.Item style={{ display: "none" }} name={"endBusPrice"}>
                  <Input style={{ display: "none" }} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  label={"Từ Jasmine đến"}
                  name={"endLocationId"}
                >
                  <LocationSelect testId="endBus-Location" />
                </Form.Item>
                <Form.Item
                  label={`Loại Bus đi`}
                  name={"endBusId"}
                  rules={[
                    {
                      required: !!fromLocationId || !!endLocationId,
                      message: "Không được bỏ trống",
                    },
                  ]}
                >
                  <BusSelect
                    filterValue={{
                      locationFromId: Utils.HaGiangLocationId,
                      locationToId: endLocationId,
                      date: null,
                      dockerId: editingComboTour?.Id,
                      // ? editingBusCombos?.length > 0
                      //   ? editingComboTour.Id
                      //   : undefined
                      // : undefined,
                    }}
                    testId="endBus-Bus"
                    onChange={(bus) => {
                      comboTourForm.setFieldValue("endBusId", bus?.id);
                      comboTourForm.setFieldValue(
                        "endBusPrice",
                        bus ? Utils.toNumber(bus.price, 0) : 0
                      );
                    }}
                    busPriceProp="price"
                  />
                </Form.Item>
              </div>
            </li>
            <Divider style={{ marginBlock: 12 }} />
          </ul>
        )}
      </Form>
      {hasCombo && (
        <li>
          <ExtraServiceTab
            titleClassName={styles.comboSection_title}
            form={extraServiceForm}
            ticketStartDate={moment().toISOString()}
            extraServices={selectExtraServices.filter(
              (extraService) =>
                [ExtraServiceCode.CHANGE_ROOM].some(
                  (code) => code === extraService.code
                ) || extraService.type === ServiceType.HANGHOA
            )}
            selectedExtraServices={selectedExtraServices}
            setSelectedService={(args) => setSelectedExtraServices(args)}
            formStyle={{ paddingLeft: 10 }}
            className={styles.extraServiceTab}
          />
        </li>
      )}
      {/* <Typography.Text
        strong
        style={{
          color: "#5998e5",
          fontSize: 20,
          position: "absolute",
          bottom: 20,
        }}
      >{`Tổng: ${Utils.formatCurrency(
        calculateTotalPrice()
      )}`}</Typography.Text> */}
    </>
  );
};
