import { Form, Input, Modal, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { MotorTicketType } from "common/define-types";
import { GroupDriverSelect } from "components/Selects/GroupDriverSelect";
import { GroupVehicleSelect } from "components/Selects/GroupVehicleSelect";
import moment from "moment";
import { useEffect } from "react";
import { useSelectorRoot } from "store/store";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (args: {
    driverId: string;
    motorbikeId?: string;
    typeMotor?: MotorTicketType;
  }) => void;
}
export const AddStaffModal = ({
  isOpen,
  handleClose,
  handleSubmit,
}: IProps) => {
  const [addStaffForm] = useForm();
  const handleAdd = async () => {
    const staffValue = await addStaffForm.validateFields().catch((err) => {
      console.log(err);
      notification.error({ message: "Vui lòng nhập đủ thông tin" });
    });
    if (staffValue) {
      handleSubmit(staffValue);
      handleClose();
    }
  };
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);
  const selectMotorbikes = useSelectorRoot(
    (state) => state.group.allMotorbikes
  );
  const selectAdditionalMotorOptions = useSelectorRoot(
    (state) => state.motorbike.additionalMotor
  );

  useEffect(() => {
    addStaffForm.resetFields();
  }, [isOpen, addStaffForm]);

  return (
    <Modal
      open={isOpen}
      onOk={handleAdd}
      onCancel={() => {
        handleClose();
        addStaffForm.resetFields();
      }}
      title={"Thêm nhân viên vào group"}
      centered
    >
      <Form
        form={addStaffForm}
        labelAlign="left"
        labelCol={{ span: 5 }}
        style={{ paddingBlock: 8 }}
        initialValues={{
          groupName: `Empty_${moment().format("DDMMYYYY")}`,
        }}
      >
        <Form.Item
          name={"driverId"}
          label={"Nhân viên"}
          id="Driver-Dropdown"
          rules={[{ required: true, message: "Không được bỏ trống" }]}
        >
          <GroupDriverSelect
            testId="Driver-Dropdown"
            defaultOptions={selectDrivers}
            onClear={() => addStaffForm.setFieldValue("driverId", null)}
            onChange={(value) => {
              addStaffForm.setFieldValue("driverId", value.id);
            }}
            valuePropName="id"
            params={{
              startDate: moment().startOf("day").add(7, "hours").toISOString(),
              endDate: moment(selectGroupSelected?.endDate)
                .endOf("day")
                .add(7, "hours")
                .toISOString(),
              isAvailable: true,
              leaderGroupId: selectGroupSelected?.id,
            }}
          />
        </Form.Item>
        <Form.Item
          name={"motorbikeId"}
          label={"Xe"}
          id="Motorbike-Dropdown"
        >
          <GroupVehicleSelect
            testId="Motorbike-Dropdown"
            defaultOptions={selectMotorbikes}
            onClear={() => addStaffForm.setFieldValue("motorbikeId", null)}
            onChange={(value, type) => {
              addStaffForm.setFieldValue("motorbikeId", value.id);
              addStaffForm.setFieldValue("typeMotor", type);
            }}
            valuePropName="id"
            params={{
              startDate: moment().startOf("day").add(7, "hours").toISOString(),
              endDate: moment(selectGroupSelected?.endDate)
                .endOf("day")
                .add(7, "hours")
                .toISOString(),
              isAvailable: true,
            }}
            additionalOptions={selectAdditionalMotorOptions}
          />
        </Form.Item>
        <Form.Item name={"typeMotor"} style={{ display: "none" }}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
