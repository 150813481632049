import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createMotorbike,
  deleteMotorbikeById,
  getAllMotorbikes,
  updateMotorbike,
} from "api/motorbike.api";
import { saveBrokeMotorbikeTickets } from "api/motorbikeTicket.api";
import { ISaveBrokeMotorbikeTicket } from "api/types/motorbikeTicket";
import {
  ICreateMotorbike,
  IMotorbike,
  IMotorbikeFilterValue,
  IMotorbikesResult,
  IParams,
  IUpdateMotorbike,
  MotorTicketType,
  RootEpic,
  VehicleType,
} from "common/define-types";
import { catchError, filter, mergeMap, switchMap, concat, of } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { operatorMotorbikeSlice } from "./MotorbikeOperatorSlice";

export interface MotorbikeState {
  isLoading: boolean;
  isSubmitting: boolean;
  motorbikes: IMotorbike[] | [];
  errMsg: string | null;
  motorbikesResult: IMotorbikesResult | null;
  addModalOpen: boolean;
  deletingMotorbikeId: string | null;
  editingMotorbike: IMotorbike | null;
  filterValue: IMotorbikeFilterValue | null;
  additionalMotor: any[];
}

const initialState: MotorbikeState = {
  isLoading: false,
  isSubmitting: false,
  motorbikes: [],
  errMsg: null,
  motorbikesResult: null,
  addModalOpen: false,
  deletingMotorbikeId: null,
  editingMotorbike: null,
  filterValue: {
    search: "",
  },
  additionalMotor: [
    {
      motorNumber: "Xe nhân viên có vé",
      type: MotorTicketType.NhanVienVe,
      id: MotorTicketType.NhanVienVe.toString(),
    },
    {
      motorNumber: "Xe nhân viên không vé",
      type: MotorTicketType.NhanVien,
      id: MotorTicketType.NhanVien.toString(),
    },
    {
      motorNumber: "Xe riêng của khách",
      type: MotorTicketType.Rieng,
      id: MotorTicketType.Rieng.toString(),
    },
    {
      motorNumber: "Xe thuê ngoài",
      type: MotorTicketType.ThueNgoai,
      id: MotorTicketType.ThueNgoai.toString(),
    },
  ],
};

export const motorbikeSlice = createSlice({
  name: "motorbike",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchMotorbikes: (state, action: PayloadAction<IParams & { vehicleType?: VehicleType } | undefined>) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.addModalOpen = false;
      state.editingMotorbike = null;
      state.deletingMotorbikeId = null;
      state.errMsg = null;
    },
    setMotorbikes: (state, action: PayloadAction<IMotorbike[]>) => {
      state.motorbikes = action.payload;
      state.isLoading = false;
      console.log("motorbikes: ", state.motorbikes);
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setMotorbikesResult: (state, action: PayloadAction<IMotorbikesResult>) => {
      state.motorbikesResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editMotorbike: (state, action: PayloadAction<IMotorbike | null>) => {
      state.editingMotorbike = action.payload;
    },
    addMotorbike: (state, action: PayloadAction<ICreateMotorbike>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteMotorbike: (state, action: PayloadAction<string>) => {
      state.deletingMotorbikeId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    saveMotorbike: (state, action: PayloadAction<IUpdateMotorbike>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    saveBrokeMotorbikeTicket: (
      state,
      action: PayloadAction<ISaveBrokeMotorbikeTicket[]>
    ) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setMotorbikeFilterValue: (
      state,
      action: PayloadAction<IMotorbikeFilterValue>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
    },
  },
});
const getMotorbikes$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchMotorbikes.match),
    switchMap((re) => {
      return getAllMotorbikes({ ...re.payload, 
        vehicleType: re.payload?.vehicleType || VehicleType.ALL, }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const motorbikes = res.results;
            return [
              motorbikeSlice.actions.setMotorbikes(motorbikes ?? []),
              motorbikeSlice.actions.setMotorbikesResult(res),
            ];
          } else {
            return [motorbikeSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          motorbikeSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách xe"
          ),
        ])
      );
    })
  );
const getMotorbikesWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setMotorbikeFilterValue.match),
    switchMap((re) => {
      return concat(
        of(motorbikeSlice.actions.setLoading(true)),
        getAllMotorbikes({
          // ...state$.value.motorbike.motorbikesResult,
          ...re.payload,
          vehicleType: re.payload.vehicleType || VehicleType.ALL,
        }).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error && res.results) {
              const motorbikes = res.results;
              return [
                motorbikeSlice.actions.setMotorbikes(motorbikes ?? []),
                motorbikeSlice.actions.setMotorbikesResult(res),
              ];
            } else {
              return [motorbikeSlice.actions.setErrMsg(res?.response.error)];
            }
          }),
          catchError((e: AjaxError) => [
            motorbikeSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi lấy danh sách xe"
            ),
          ])
        ),
        of(motorbikeSlice.actions.setLoading(false)),
      );
    })
  );
const addMotorbikes$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addMotorbike.match),
    switchMap((re) => {
      return createMotorbike([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.motorbike.motorbikesResult) {
              return [
                motorbikeSlice.actions.fetchMotorbikes(
                  state$.value.motorbike.motorbikesResult
                ),
              ];
            }
            return [motorbikeSlice.actions.fetchMotorbikes()];
          } else {
            return [motorbikeSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          motorbikeSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm xe"),
        ])
      );
    })
  );
const saveMotorbikes$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveMotorbike.match),
    switchMap((re) => {
      return updateMotorbike(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.motorbike.motorbikesResult) {
              return [
                motorbikeSlice.actions.fetchMotorbikes(
                  state$.value.motorbike.motorbikesResult
                ),
              ];
            }
            return [motorbikeSlice.actions.fetchMotorbikes()];
          } else {
            return [motorbikeSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          motorbikeSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lưu thông tin xe"
          ),
        ])
      );
    })
  );
const deleteMotorbikes$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteMotorbike.match),
    switchMap((re) => {
      return deleteMotorbikeById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.motorbike.motorbikesResult) {
              return [
                motorbikeSlice.actions.fetchMotorbikes({
                  ...state$.value.motorbike.motorbikesResult,
                  page: 1,
                }),
              ];
            }
            return [motorbikeSlice.actions.fetchMotorbikes()];
          } else {
            return [motorbikeSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          motorbikeSlice.actions.setErrMsg("Có lỗi xảy ra khi xóa xe"),
        ])
      );
    })
  );

const saveBrokeMotorbikeTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveBrokeMotorbikeTicket.match),
    switchMap((re) => {
      return saveBrokeMotorbikeTickets(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              operatorMotorbikeSlice.actions.fetchMotorbikes({
                ...state$.value.operatorMotorbike.filterValue,
                ...state$.value.operatorMotorbike.motorbikesResult,
              }),
            ];
          } else {
            return [motorbikeSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          motorbikeSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );

export const {
  fetchMotorbikes,
  setMotorbikes,
  setErrMsg,
  addMotorbike,
  setAddModalOpen,
  saveMotorbike,
  editMotorbike,
  deleteMotorbike,
  saveBrokeMotorbikeTicket,
  setMotorbikeFilterValue,
} = motorbikeSlice.actions;

export const MotorbikeEpics = [
  getMotorbikes$,
  addMotorbikes$,
  saveMotorbikes$,
  deleteMotorbikes$,
  saveBrokeMotorbikeTicket$,
  getMotorbikesWhenFilter$,
];

export const motorbikeReducer = motorbikeSlice.reducer;
