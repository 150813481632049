import { Token_ResponseToken } from "common/define-types";
import Utils from "../common/Utils";
import { throwError, defer } from "rxjs";
import { ajax, AjaxError, AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map, retry, switchMap } from "rxjs/operators";
import IdentityApi from "./identity/identity.api";
import { store } from "store/store";
import { setIsRefreshing } from "store/controls/LoginEpic";

/** types */
interface ParamRequest {
  method?: string;
  async?: boolean;
  headers?: Readonly<Record<string, any>>;
  timeout?: number;
  crossDomain?: boolean;
  withCredentials?: boolean;
  responseType?: XMLHttpRequestResponseType;
}
type PartAjaxRequest = Omit<ParamRequest, "url" | "method" | "body">;
type HttpMethod = "GET" | "POST" | "DELETE" | "PUT";
interface Param {
  url: string;
  data?: unknown;
  headers?: PartAjaxRequest;
}
const navigationLogin = () => {
  localStorage.clear();
  // const { dispatch } = store;
  // dispatch(setAuthenticated(false));
  // dispatch(logoutAction());
  document.location.href = "/login";
};

function mapResponse(res: AjaxResponse<any>) {
  // console.log(JSON.stringify(res.response))
  if (res.response !== undefined && res.response !== null) {
    return res.response;
  }
}
function mapResponseHeader(res: AjaxResponse<any>) {
  if (res.response) {
    return res;
  }
}
export const refreshAccessToken = (refreshToken: string | null) => {
  const { dispatch } = store;
  dispatch(setIsRefreshing(true));

  return IdentityApi.refreshToken(refreshToken).pipe(
    map(
      (tokenRes) => {
        if (tokenRes) {
          const newRes = tokenRes as Token_ResponseToken;
          const token = newRes.access_token;
          Utils.token = token;
          Utils.refresh = newRes.refresh_token;
          Utils.setLocalStorage(Utils.constant.token, newRes);
          // console.log(token);
          dispatch(setIsRefreshing(false));
          return token;
        } else {
          navigationLogin();
          dispatch(setIsRefreshing(false));
        }
      },
      catchError((err) => {
        console.log(err);

        dispatch(setIsRefreshing(false));
        navigationLogin();
        return throwError(err);
      })
    ),
    catchError((err) => {
      console.log(err);

      dispatch(setIsRefreshing(false));
      navigationLogin();
      return throwError(err);
    })
  );
};

function handleError$(err: AjaxError): Observable<unknown> {
  if (err) {
    const refreshToken = Utils.refresh;
    if (err.status === 401 && refreshToken) {
      return refreshAccessToken(refreshToken);
    }
    if (err.status === 400 && err.message === "invalid_grant") {
      navigationLogin();
    }
  }
  // navigationLogin();
  return throwError(err);
}

function mapAjaxRequest(request?: PartAjaxRequest) {
  const mapHeaders = request?.headers
    ? ({ ...request.headers } as PartAjaxRequest)
    : undefined;
  const newHeaders = {
    Authorization: Utils.token ? `Bearer ${Utils.token}` : "",
    Accept: "*/*",
    "Content-Type": "application/json",
    ...mapHeaders,
  };
  return { ...request, headers: { ...newHeaders } };
}

function commonApiCall(
  method: HttpMethod,
  param: Param,
  isGetHeader = false
): Observable<unknown> {
  const { url, data, headers } = param;
  const body = data;
  return defer(() => {
    const newHeaders = mapAjaxRequest(headers);
    return ajax({ url, method, body, ...newHeaders });
  }).pipe(
    map((res) => (!isGetHeader ? mapResponse(res) : mapResponseHeader(res))),
    catchError((err, source) => handleError$(err).pipe(switchMap(() => source)))
  );
}
/** base class */
export default class HttpClient {
  static get(url: string, headers?: PartAjaxRequest): Observable<unknown> {
    return commonApiCall("GET", { url, headers });
  }

  static post(
    url: string,
    data: unknown,
    headers?: PartAjaxRequest,
    isGetHeader?: boolean
  ): Observable<unknown> {
    return commonApiCall("POST", { url, data, headers }, isGetHeader);
  }

  static delete(
    url: string,
    data?: unknown,
    headers?: PartAjaxRequest
  ): Observable<unknown> {
    return commonApiCall("DELETE", { url, data, headers });
  }

  static put(
    url: string,
    data: unknown,
    headers?: PartAjaxRequest
  ): Observable<unknown> {
    return commonApiCall("PUT", { url, data, headers });
  }
  static upload(
    url: string,
    data: unknown,
    _headers?: PartAjaxRequest
  ): Observable<unknown> {
    console.log("------------------");
    console.log(url);
    data && console.log(JSON.stringify(data));
    console.log("------------------");
    return ajax({
      url,
      method: "POST",
      body: data,
      headers: {
        Authorization: Utils.token ? `Bearer ${Utils.token}` : "",
      },
    }).pipe(
      map((response: AjaxResponse<any>) => mapResponse(response)),
      retry(2),
      catchError((err) => handleError$(err))
    );
  }
}

export const ajaxErrorFlashMessage = (error: any) => {
  // const message =
  //     typeof error === 'string'
  //         ? error
  //         : (error.response &&
  //               error.response.error &&
  //               error.response.error.message) ||
  //           error.message;
  // showMessage({
  //     message: 'Oops!',
  //     description: message,
  //     type: 'danger',
  // });
};
